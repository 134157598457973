import React, { useState, useEffect } from 'react';
import DuaCard from './DuaCard';

function App() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('PWA installed');
        } else {
          console.log('PWA installation rejected');
        }
        setDeferredPrompt(null);
        setIsInstallable(false);
      });
    }
  };

  return (
    <div className='w-full items-center flex flex-col mt-8'>
    <div className='flex flex-col items-center  max-w-[600px]'>
      <div className=''></div>
      <h1>Welcome to My PWA</h1>
      {isInstallable && (
        <button onClick={handleInstallClick}>Install App</button>
      )}
      {!isInstallable && <p>This app is already installed or not installable.</p>}
      <div className='mt-8 flex flex-col gap-4'>
        <DuaCard></DuaCard>
        <DuaCard></DuaCard>

      </div>
    </div>
    </div>
  );
}

export default App;
