import React, { useState, useEffect } from 'react';

function DuaCard() {
  

  return (
    <div className='bg-amber-50 rounded-xl p-8 flex flex-col gap-2'>
        <p className='text-right text-amber-900'>اللَّهُمَّ لَكَ الْحَمْدُ أَنْتَ كَسَوْتَنِيهِ، أَسْأَلُكَ مِنْ خَيْرِهِ وَخَيْرِ مَا صُنِعَ لَهُ، وَأَعُوذُ بِكَ مِنْ شَرِّهِ وَشَرِّ مَا صُنِعَ لَهُ.</p>
        <p className='text-neutral-600'>All-llãhumme lekel-ḥamdu Ente kesewtenĩhi, es’eluke min ḣajrihi we ḣajri ma ṣuni’a lehu, we e’ũdhu bike min sherrihi we sherri mã ṣuni’a lehu.</p>
        <p className='font-semibold text-neutral-900'>O Allah! Ty të takon lavdia! Ti më bëre rrizk këtë veshje. Të kërkoj të ma bësh të dobishme dhe të më mundësosh përdorimin e saj në të mirën për të cilën është prodhuar! Kërkoj të më mbrosh nga sherri i saj dhe nga përdorimi i saj për gjynahe!</p>
        <p className='text-sm text-neutral-600'>Ebu Davudi (Muhtesar Shemail).</p>
    </div>
  );
}

export default DuaCard;
